import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Box`}</h1>
    <p>{`Box is an empty container with padding.`}</p>
    <ComponentPreview componentName="box--default-box" hasHTML hasReact hasAngular titleAttr="Box Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Box to create a padded container. The whitespace visually separates elements from each other.`}</p>
    <p>{`Box is an empty container with padding, so the example with black borders and gray background are for illustrative purposes only.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Default Box padding is set to Medium (`}<inlineCode parentName="li">{`16px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-m`}</inlineCode>{`).`}</li>
      <li parentName="ul">{`Box has 20 built in padding options that follow our `}<a parentName="li" {...{
          "href": "/using-spark/foundations/space"
        }}>{`Spacing`}</a>{` proportions.`}<ul parentName="li">
          <li parentName="ul">{`Flush (`}<inlineCode parentName="li">{`0px`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Tiny (`}<inlineCode parentName="li">{`4px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-xs`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Small (`}<inlineCode parentName="li">{`8px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-s`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Medium (`}<inlineCode parentName="li">{`16px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-m`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Large (`}<inlineCode parentName="li">{`32px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-l`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Huge (`}<inlineCode parentName="li">{`64px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-xl`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Short Tiny (`}<inlineCode parentName="li">{`2px 4px 2px 4px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-short-xs`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Short Small (`}<inlineCode parentName="li">{`4px 8px 4px 8px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-short-s`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Short Medium (`}<inlineCode parentName="li">{`8px 16px 8px 16px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-short-m`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Short Large (`}<inlineCode parentName="li">{`16px 32px 16px 32px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-short-l`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Short Huge (`}<inlineCode parentName="li">{`32px 64px 32px 64px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-short-xl`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Tall Tiny (`}<inlineCode parentName="li">{`6px 4px 6px 4px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-tall-xs`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Tall Small (`}<inlineCode parentName="li">{`12px 8px 12px 8px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-tall-s`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Tall Medium (`}<inlineCode parentName="li">{`24px 16px 24px 16px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-tall-m`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Tall Large (`}<inlineCode parentName="li">{`48px 32px 48px 32px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-tall-l`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Inset Tall Huge (`}<inlineCode parentName="li">{`96px 64px 96px 64px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-inset-tall-xl`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Misc A (`}<inlineCode parentName="li">{`24px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-misc-a`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Misc B (`}<inlineCode parentName="li">{`40px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-misc-b`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Misc C (`}<inlineCode parentName="li">{`48px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-misc-c`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Misc D (`}<inlineCode parentName="li">{`80px`}</inlineCode>{`, `}<inlineCode parentName="li">{`$sprk-space-misc-d`}</inlineCode>{`)`}</li>
        </ul></li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Box is an empty container with padding.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      